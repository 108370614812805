import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "diy-gb-carts-knowledge-dump"
    }}>{`DIY GB carts knowledge dump`}</h1>
    <h2 {...{
      "id": "rippingwriting-roms"
    }}>{`Ripping/Writing ROMs`}</h2>
    <p>{`I've got a Joey Jr from BennVenn`}</p>
    <h2 {...{
      "id": "where-to-buy-carts"
    }}>{`Where to buy carts`}</h2>
    <p>{`AliExpress sellers selling bootleg carts, most of which are rewritable with the Joey Jr`}</p>
    <h2 {...{
      "id": "patching"
    }}>{`Patching`}</h2>
    <h3 {...{
      "id": "battery-less-patching"
    }}>{`Battery-less patching`}</h3>
    <h3 {...{
      "id": "rom-hacks"
    }}>{`ROM hacks`}</h3>
    <h3 {...{
      "id": "diy-rom-hacks"
    }}>{`DIY ROM hacks`}</h3>
    <h2 {...{
      "id": "emulators"
    }}>{`Emulators`}</h2>
    <h3 {...{
      "id": "goomba--goomba-color"
    }}>{`Goomba / Goomba Color`}</h3>
    <h3 {...{
      "id": "pocketnes--pocketsnes"
    }}>{`PocketNES / PocketSNES`}</h3>
    <h2 {...{
      "id": "converting-video"
    }}>{`Converting video`}</h2>
    <h2 {...{
      "id": "making-own-demo-carts"
    }}>{`Making own demo carts`}</h2>
    <h2 {...{
      "id": "making-own-games"
    }}>{`Making own games`}</h2>
    <h3 {...{
      "id": "gbstudio"
    }}>{`GBStudio`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      